import { useState } from "react";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function EditModal({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [name, setName] = useState(data.name || "");

  const onFormSubmit = async e => {

    e.preventDefault();
    try {
      if (data.ID) {
        await api.updateLabel(data.ID, {
          name,
        });
      } else {
        await api.createLabel({ name, });
      }

      api.createSnapshot();
      toastService.send({ title: "Success", message: "Label is opgeslagen" });
      hideModal();
    } catch (error) {
      console.error(error);
      toastService.send({ title: "An error occured", message: error });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Label {data.ID ? "bewerken" : "aanmaken"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Naam</Form.Label>
                <Form.Control type="text" required onChange={e => setName(e.target.value)} value={name} />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={snapshot}>
                {data.ID ? (
                  <span>
                    <i className="far fa-edit" />
                    &nbsp;Bewerk
                  </span>
                ) : (
                  <span>
                    <i className="far fa-plus" />
                    &nbsp;Aanmaken{" "}
                  </span>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
