// @flow
/* eslint-disable no-use-before-define */
/**
 * SAT
 * Staff Assignments Tool
 *
 * The version of the OpenAPI document: 1.0.0
 *
 * NOTE: This class is auto generated by OpenAPI-Generator
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH: string = "https://api.sat.maartje.dev".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 */
export type FetchAPI = {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 */
export type FetchArgs = {
    url: string;
    options: {};
}

/**
 *
 * @export
 */
export type RequestOptions = {
    headers?: {};
    query?: {};
    body?: string | FormData;
}

/**
 * * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: string = "RequiredError"
    constructor(field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 */
export type Ambt = {
    /**
     * 
     * @type {number}
     * @memberof Ambt
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof Ambt
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Ambt
     */
    collegaID?: number;
}

/**
 * 
 * @export
 */
export type Assignment = {
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    ID?: number;
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    percentageOverride?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Assignment
     */
    opoResponsible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    internshipStudents?: number;
    /**
     * 
     * @type {OLA}
     * @memberof Assignment
     */
    ola?: OLA;
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    olaID?: number;
    /**
     * 
     * @type {Collega}
     * @memberof Assignment
     */
    collega?: Collega;
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    collegaID?: number;
}

/**
 * 
 * @export
 */
export type Collega = {
    /**
     * 
     * @type {number}
     * @memberof Collega
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof Collega
     */
    uNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Collega
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Collega
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Collega
     */
    initials?: string;
    /**
     * 
     * @type {number}
     * @memberof Collega
     */
    leaveSchedule?: number;
    /**
     * 
     * @type {number}
     * @memberof Collega
     */
    percentageInUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof Collega
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof Collega
     */
    fundingSource?: string;
    /**
     * 
     * @type {string}
     * @memberof Collega
     */
    color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Collega
     */
    noForfait?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Collega
     */
    isSATUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Collega
     */
    nameOrangeGuide?: string;
    /**
     * 
     * @type {Array<FinanceSource>}
     * @memberof Collega
     */
    financeSources?: Array<FinanceSource>;
    /**
     * 
     * @type {Array<Ambt>}
     * @memberof Collega
     */
    ambts?: Array<Ambt>;
    /**
     * 
     * @type {Array<CollegaProgramme>}
     * @memberof Collega
     */
    programmes?: Array<CollegaProgramme>;
}

/**
 * 
 * @export
 */
export type CollegaProgramme = {
    /**
     * 
     * @type {number}
     * @memberof CollegaProgramme
     */
    ID?: number;
    /**
     * 
     * @type {number}
     * @memberof CollegaProgramme
     */
    collegaID?: number;
    /**
     * 
     * @type {Collega}
     * @memberof CollegaProgramme
     */
    collega?: Collega;
    /**
     * 
     * @type {number}
     * @memberof CollegaProgramme
     */
    programmeID?: number;
    /**
     * 
     * @type {Programme}
     * @memberof CollegaProgramme
     */
    programme?: Programme;
    /**
     * 
     * @type {number}
     * @memberof CollegaProgramme
     */
    percentage?: number;
}

/**
 * 
 * @export
 */
export type Error = {
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
}

/**
 * 
 * @export
 */
export type FinanceSource = {
    /**
     * 
     * @type {number}
     * @memberof FinanceSource
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof FinanceSource
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof FinanceSource
     */
    collegaID?: number;
    /**
     * 
     * @type {number}
     * @memberof FinanceSource
     */
    percentage?: number;
    /**
     * 
     * @type {string}
     * @memberof FinanceSource
     */
    comments?: string;
}

/**
 * 
 * @export
 */
export type MyAssignment = {
    /**
     * 
     * @type {number}
     * @memberof MyAssignment
     */
    ID?: number;
    /**
     * 
     * @type {number}
     * @memberof MyAssignment
     */
    percentageOverride?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MyAssignment
     */
    opoResponsible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MyAssignment
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof MyAssignment
     */
    internshipStudents?: number;
    /**
     * 
     * @type {MyOLA}
     * @memberof MyAssignment
     */
    ola?: MyOLA;
    /**
     * 
     * @type {number}
     * @memberof MyAssignment
     */
    olaID?: number;
    /**
     * 
     * @type {Collega}
     * @memberof MyAssignment
     */
    collega?: Collega;
    /**
     * 
     * @type {number}
     * @memberof MyAssignment
     */
    collegaID?: number;
}

/**
 * 
 * @export
 */
export type MyOLA = {
    /**
     * 
     * @type {number}
     * @memberof MyOLA
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof MyOLA
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof MyOLA
     */
    opoID?: number;
    /**
     * 
     * @type {OPO}
     * @memberof MyOLA
     */
    opo?: Any;
    /**
     * 0 = all year, 1 and 2 are semesters
     * @type {number}
     * @memberof MyOLA
     */
    semester?: number;
    /**
     * 
     * @type {number}
     * @memberof MyOLA
     */
    contactQ1?: number;
    /**
     * 
     * @type {number}
     * @memberof MyOLA
     */
    contactQ2?: number;
    /**
     * 
     * @type {number}
     * @memberof MyOLA
     */
    contactQ3?: number;
    /**
     * 
     * @type {number}
     * @memberof MyOLA
     */
    contactQ4?: number;
    /**
     * 
     * @type {number}
     * @memberof MyOLA
     */
    ectsCredits?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MyOLA
     */
    internship?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MyOLA
     */
    zCode?: string;
    /**
     * 
     * @type {number}
     * @memberof MyOLA
     */
    realECTSCredits?: number;
    /**
     * 
     * @type {Array<OLAPhase>}
     * @memberof MyOLA
     */
    phases?: Array<OLAPhase>;
    /**
     * 
     * @type {Array<Assignment>}
     * @memberof MyOLA
     */
    assignments?: Array<Assignment>;
}

/**
 * 
 * @export
 */
export type OLA = {
    /**
     * 
     * @type {number}
     * @memberof OLA
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof OLA
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof OLA
     */
    opoID?: number;
    /**
     * 0 = all year, 1 and 2 are semesters
     * @type {number}
     * @memberof OLA
     */
    semester?: number;
    /**
     * 
     * @type {number}
     * @memberof OLA
     */
    contactQ1?: number;
    /**
     * 
     * @type {number}
     * @memberof OLA
     */
    contactQ2?: number;
    /**
     * 
     * @type {number}
     * @memberof OLA
     */
    contactQ3?: number;
    /**
     * 
     * @type {number}
     * @memberof OLA
     */
    contactQ4?: number;
    /**
     * 
     * @type {number}
     * @memberof OLA
     */
    ectsCredits?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OLA
     */
    internship?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OLA
     */
    zCode?: string;
    /**
     * 
     * @type {number}
     * @memberof OLA
     */
    realECTSCredits?: number;
    /**
     * 
     * @type {Array<OLAPhase>}
     * @memberof OLA
     */
    phases?: Array<OLAPhase>;
}

/**
 * 
 * @export
 */
export type OLAPhase = {
    /**
     * 
     * @type {number}
     * @memberof OLAPhase
     */
    ID?: number;
    /**
     * 
     * @type {number}
     * @memberof OLAPhase
     */
    olaID?: number;
    /**
     * 
     * @type {number}
     * @memberof OLAPhase
     */
    phaseID?: number;
    /**
     * 
     * @type {number}
     * @memberof OLAPhase
     */
    surplus?: number;
    /**
     * 
     * @type {number}
     * @memberof OLAPhase
     */
    numberOfClassesOverride?: number;
    /**
     * 
     * @type {Phase}
     * @memberof OLAPhase
     */
    phase?: Phase;
}

/**
 * 
 * @export
 */
export type OPO = {
    /**
     * 
     * @type {number}
     * @memberof OPO
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof OPO
     */
    zCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OPO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OPO
     */
    comments?: string;
    /**
     * 
     * @type {Array<OPOTag>}
     * @memberof OPO
     */
    tags?: Array<OPOTag>;
    /**
     * 
     * @type {Array<OLA>}
     * @memberof OPO
     */
    olas?: Array<OLA>;
}

/**
 * 
 * @export
 */
export type OPOTag = {
    /**
     * 
     * @type {number}
     * @memberof OPOTag
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof OPOTag
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof OPOTag
     */
    opoID?: number;
}

/**
 * 
 * @export
 */
export type Parallel = {
    /**
     * 
     * @type {number}
     * @memberof Parallel
     */
    ID?: number;
    /**
     * 
     * @type {OLA}
     * @memberof Parallel
     */
    mainOLA?: OLA;
    /**
     * 
     * @type {number}
     * @memberof Parallel
     */
    mainOLAID?: number;
    /**
     * 
     * @type {OLA}
     * @memberof Parallel
     */
    subOLA?: OLA;
    /**
     * 
     * @type {number}
     * @memberof Parallel
     */
    subOLAID?: number;
    /**
     * 
     * @type {number}
     * @memberof Parallel
     */
    level?: number;
    /**
     * 
     * @type {string}
     * @memberof Parallel
     */
    comment?: string;
}

/**
 * 
 * @export
 */
export type ParameterClassSizeLoad = {
    /**
     * 
     * @type {number}
     * @memberof ParameterClassSizeLoad
     */
    ID?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterClassSizeLoad
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterClassSizeLoad
     */
    max?: number;
    /**
     * 
     * @type {string}
     * @memberof ParameterClassSizeLoad
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ParameterClassSizeLoad
     */
    shortName?: string;
    /**
     * 
     * @type {number}
     * @memberof ParameterClassSizeLoad
     */
    firstLoad?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterClassSizeLoad
     */
    parallelLoad?: number;
}

/**
 * 
 * @export
 */
export type ParameterECTSLoad = {
    /**
     * 
     * @type {number}
     * @memberof ParameterECTSLoad
     */
    ID?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterECTSLoad
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterECTSLoad
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterECTSLoad
     */
    hoursPerGrade?: number;
}

/**
 * 
 * @export
 */
export type ParameterGeneral = {
    /**
     * 
     * @type {number}
     * @memberof ParameterGeneral
     */
    opoResposibleExtra?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterGeneral
     */
    fullTimeHour?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterGeneral
     */
    contactHoursLimit?: number;
}

/**
 * 
 * @export
 */
export type Phase = {
    /**
     * 
     * @type {number}
     * @memberof Phase
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof Phase
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Phase
     */
    numberOfStudents?: number;
    /**
     * 
     * @type {Programme}
     * @memberof Phase
     */
    programme?: Programme;
    /**
     * 
     * @type {number}
     * @memberof Phase
     */
    programmeID?: number;
    /**
     * 
     * @type {number}
     * @memberof Phase
     */
    numberOfClasses?: number;
    /**
     * 
     * @type {string}
     * @memberof Phase
     */
    color?: string;
}

/**
 * 
 * @export
 */
export type Programme = {
    /**
     * 
     * @type {number}
     * @memberof Programme
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof Programme
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Programme
     */
    internshipPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof Programme
     */
    forfaitFactor?: number;
    /**
     * 0 = bachelors, 1 = graduates
     * @type {number}
     * @memberof Programme
     */
    programmeType?: number;
    /**
     * 
     * @type {number}
     * @memberof Programme
     */
    opoResponsiblePercentage?: number;
    /**
     * 
     * @type {Array<Collega>}
     * @memberof Programme
     */
    collegas?: Array<Collega>;
}

/**
 * 
 * @export
 */
export type Role = {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    name?: string;
    /**
     * 
     * @type {Array<RoleEndpoints>}
     * @memberof Role
     */
    endpoints?: Array<RoleEndpoints>;
    /**
     * 
     * @type {Array<RoleEndpoints>}
     * @memberof Role
     */
    verbs?: Array<RoleEndpoints>;
}

/**
 * 
 * @export
 */
export type RoleEndpoints = {
    /**
     * 
     * @type {string}
     * @memberof RoleEndpoints
     */
    content?: string;
}

/**
 * 
 * @export
 */
export type RuleError = {
    /**
     * 
     * @type {string}
     * @memberof RuleError
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RuleError
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof RuleError
     */
    severity: number;
    /**
     * 
     * @type {string}
     * @memberof RuleError
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof RuleError
     */
    subjectType?: string;
    /**
     * 
     * @type {number}
     * @memberof RuleError
     */
    programmeID?: number;
}

/**
 * 
 * @export
 */
export type Snapshot = {
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    fileName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Snapshot
     */
    time?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Snapshot
     */
    isTheUltimateSourceoOfTruth?: boolean;
}

/**
 * 
 * @export
 */
export type Status = {
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    message: string;
}

/**
 * 
 * @export
 */
export type Task = {
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    percentage?: number;
    /**
     * 
     * @type {Array<Programme>}
     * @memberof Task
     */
    programmes?: Array<Programme>;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    hasForfait?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    educationalActivity?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    comment?: string;
}

/**
 * 
 * @export
 */
export type TaskAssignment = {
    /**
     * 
     * @type {number}
     * @memberof TaskAssignment
     */
    ID?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskAssignment
     */
    taskID?: number;
    /**
     * 
     * @type {Task}
     * @memberof TaskAssignment
     */
    task?: Task;
    /**
     * 
     * @type {number}
     * @memberof TaskAssignment
     */
    collegaID?: number;
    /**
     * 
     * @type {Collega}
     * @memberof TaskAssignment
     */
    collega?: Collega;
    /**
     * 
     * @type {number}
     * @memberof TaskAssignment
     */
    programmeID?: number;
    /**
     * 
     * @type {Programme}
     * @memberof TaskAssignment
     */
    programme?: Programme;
    /**
     * 
     * @type {number}
     * @memberof TaskAssignment
     */
    percentageOverride?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskAssignment
     */
    comment?: string;
}

/**
 * 
 * @export
 */
export type TaskCategory = {
    /**
     * 
     * @type {number}
     * @memberof TaskCategory
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskCategory
     */
    name: string;
}

/**
 * 
 * @export
 */
export type Label = {
    /**
     * 
     * @type {number}
     * @memberof Label
     */
    ID?: number;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    name: string;
}



/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check if the user is authenticated
         * @throws {RequiredError}
         */
        checkAuth(options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/auth/check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check for errors in the database using the rule engine
         * @throws {RequiredError}
         */
        checkRules(options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/rules/check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new assignment
         * @throws {RequiredError}
         */
        createAssignment(assignment?: Assignment, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/assignment/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof assignment !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(assignment != null ? assignment : {}) : (((assignment: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new class size parameter
         * @throws {RequiredError}
         */
        createClassSizeParameter(parameterClassSizeLoad?: ParameterClassSizeLoad, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/parameters/classsize/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof parameterClassSizeLoad !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(parameterClassSizeLoad != null ? parameterClassSizeLoad : {}) : (((parameterClassSizeLoad: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new collega
         * @throws {RequiredError}
         */
        createCollega(collega?: Collega, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/collega`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof collega !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(collega != null ? collega : {}) : (((collega: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new ECTS load parameter
         * @throws {RequiredError}
         */
        createECTSLoadParameter(parameterECTSLoad?: ParameterECTSLoad, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/parameters/ectsload/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof parameterECTSLoad !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(parameterECTSLoad != null ? parameterECTSLoad : {}) : (((parameterECTSLoad: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new OLA
         * @throws {RequiredError}
         */
        createOLA(oLA?: OLA, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/ola`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof oLA !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(oLA != null ? oLA : {}) : (((oLA: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new OPO
         * @throws {RequiredError}
         */
        createOPO(oPO?: OPO, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/opo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof oPO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(oPO != null ? oPO : {}) : (((oPO: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new parallel
         * @throws {RequiredError}
         */
        createParallel(parallel?: Parallel, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/parallel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof parallel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(parallel != null ? parallel : {}) : (((parallel: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new phase
         * @throws {RequiredError}
         */
        createPhase(phase?: Phase, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/phase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof phase !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(phase != null ? phase : {}) : (((phase: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new programme
         * @throws {RequiredError}
         */
        createProgramme(programme?: Programme, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/programme`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof programme !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(programme != null ? programme : {}) : (((programme: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new snapshot
         * @throws {RequiredError}
         */
        createSnapshot(options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/snapshot`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new task
         * @throws {RequiredError}
         */
        createTask(task?: Task, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/task/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof task !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(task != null ? task : {}) : (((task: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new taskassignment
         * @throws {RequiredError}
         */
        createTaskAssignment(taskAssignment?: TaskAssignment, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/taskassignment`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof taskAssignment !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(taskAssignment != null ? taskAssignment : {}) : (((taskAssignment: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new task category
         * @throws {RequiredError}
         */
        createTaskCategory(taskCategory?: TaskCategory, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/taskcategory/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof taskCategory !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(taskCategory != null ? taskCategory : {}) : (((taskCategory: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new label
         * @throws {RequiredError}
         */
        createLabel(label?: Label, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/label/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof label !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(label != null ? label : {}) : (((label: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the assignment with the given id
         * @throws {RequiredError}
         */
        deleteAssignment(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteAssignment.');
            }
            const localVarPath = `/v1/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the class size parameter with the given id
         * @throws {RequiredError}
         */
        deleteClassSizeParameter(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteClassSizeParameter.');
            }
            const localVarPath = `/v1/parameters/classsize/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the collega with the given id
         * @throws {RequiredError}
         */
        deleteCollega(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteCollega.');
            }
            const localVarPath = `/v1/collega/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the ECTS load parameter with the given id
         * @throws {RequiredError}
         */
        deleteECTSLoadParameter(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteECTSLoadParameter.');
            }
            const localVarPath = `/v1/parameters/ectsload/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the OLA with the given id
         * @throws {RequiredError}
         */
        deleteOLA(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteOLA.');
            }
            const localVarPath = `/v1/ola/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the OPO with the given id
         * @throws {RequiredError}
         */
        deleteOPO(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteOPO.');
            }
            const localVarPath = `/v1/opo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the parallel with the given id
         * @throws {RequiredError}
         */
        deleteParallel(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteParallel.');
            }
            const localVarPath = `/v1/parallel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the class with the given id
         * @throws {RequiredError}
         */
        deletePhase(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deletePhase.');
            }
            const localVarPath = `/v1/phase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the programme with the given id
         * @throws {RequiredError}
         */
        deleteProgramme(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteProgramme.');
            }
            const localVarPath = `/v1/programme/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the task with the given id
         * @throws {RequiredError}
         */
        deleteTask(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteTask.');
            }
            const localVarPath = `/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the taskassignment with the given id
         * @throws {RequiredError}
         */
        deleteTaskAssignment(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteTaskAssignment.');
            }
            const localVarPath = `/v1/taskassignment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the task category with the given id
         * @throws {RequiredError}
         */
        deleteTaskCategory(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteTaskCategory.');
            }
            const localVarPath = `/v1/taskcategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the task category with the given id
         * @throws {RequiredError}
         */
        deleteLabel(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteLabel.');
            }
            const localVarPath = `/v1/label/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'DELETE' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the assignment with the given id
         * @throws {RequiredError}
         */
        getAssignment(id: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getAssignment.');
            }
            const localVarPath = `/v1/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all assignments
         * @throws {RequiredError}
         */
        getAssignments(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/assignments/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all assignments for collega
         * @throws {RequiredError}
         */
        getAssignmentsByCollegaId(collegaID: number, snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/assignments/collega/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(collegaID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Task assignments for collega
         * @throws {RequiredError}
         */
        getTaskAssignmentsByCollegaId(collegaID: number, snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/taskassignments/collega/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(collegaID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all assignments in Excel
         * @throws {RequiredError}
         */
        getAssignmentsExport(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/assignments/export`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns docentenContacturenPerOpleiding
         * @throws {RequiredError}
         */
        getDocentenContacturenPerOpleidingReportPDF(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/reports/docentenContacturenPerOpleidingPDF`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getDocentenContacturenPerOpleidingReportExcel(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/reports/docentenContacturenPerOpleidingExcel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Staff Assignment report
         * @throws {RequiredError}
         */
        getStaffAssignmentReport(collegaID: number, snapshot?: string, options: RequestOptions): FetchArgs {
            if (collegaID === null || collegaID === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getClassSizeParameter.');
            }
            const localVarPath = `/v1/reports/prestatieregeling/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(collegaID)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the class size parameter with the given id
         * @throws {RequiredError}
         */
        getClassSizeParameter(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getClassSizeParameter.');
            }
            const localVarPath = `/v1/parameters/classsize/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get class sizes parameters
         * @throws {RequiredError}
         */
        getClassSizesParameters(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/parameters/classsizes/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the collega with the given id
         * @throws {RequiredError}
         */
        getCollega(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getCollega.');
            }
            const localVarPath = `/v1/collega/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all collegas
         * @throws {RequiredError}
         */
        getCollegas(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/collegas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all collegas in Excel
         * @throws {RequiredError}
         */
        getCollegasExport(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/collegas/export`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the ECTS load parameter with the given id
         * @throws {RequiredError}
         */
        getECTSLoadParameter(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getECTSLoadParameter.');
            }
            const localVarPath = `/v1/parameters/ectsload/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get ECTS loads parameters
         * @throws {RequiredError}
         */
        getECTSLoadsParameters(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/parameters/ectsloads/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get general parameters
         * @throws {RequiredError}
         */
        getGeneralParameters(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/parameters/general/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get general parameters
         * @throws {RequiredError}
         */
        getGlobalSettings(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/globalsettings/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all assignments of current user
         * @throws {RequiredError}
         */
        getMyAssignments(options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/my-assignments/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all task assignments of current user
         * @throws {RequiredError}
         */
        getMyTaskAssignments(options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/my-taskassignments/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the collega for the current logged in user
         * @throws {RequiredError}
         */
        getMyself(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/collega/myself`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the OLA with the given id
         * @throws {RequiredError}
         */
        getOLA(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getOLA.');
            }
            const localVarPath = `/v1/ola/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the OPO with the given id
         * @throws {RequiredError}
         */
        getOPO(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getOPO.');
            }
            const localVarPath = `/v1/opo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all OPOs
         * @throws {RequiredError}
         */
        getOPOs(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/opos`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the parallel with the given id
         * @throws {RequiredError}
         */
        getParallel(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getParallel.');
            }
            const localVarPath = `/v1/parallel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all parallels
         * @throws {RequiredError}
         */
        getParallels(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/parallels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the class with the given id
         * @throws {RequiredError}
         */
        getPhase(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getPhase.');
            }
            const localVarPath = `/v1/phase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all phases
         * @throws {RequiredError}
         */
        getPhases(programmeID?: number, snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/phases`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (programmeID !== undefined) {
                localVarQueryParameter['programmeID'] = ((programmeID: any): string);
            }

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the programme with the given id
         * @throws {RequiredError}
         */
        getProgramme(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getProgramme.');
            }
            const localVarPath = `/v1/programme/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all programmes
         * @throws {RequiredError}
         */
        getProgrammes(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/programmes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all roles of the current user
         * @throws {RequiredError}
         */
        getRoles(options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/auth/whoami/roles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the diff between two snapshots
         * @throws {RequiredError}
         */
        getSnapshotDiff(from: number, to: number, options: RequestOptions): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from', 'Required parameter from was null or undefined when calling getSnapshotDiff.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to', 'Required parameter to was null or undefined when calling getSnapshotDiff.');
            }
            const localVarPath = `/v1/snapshot/compare/{from}/{to}`
                .replace(`{${"from"}}`, encodeURIComponent(String(from)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all snapshots
         * @throws {RequiredError}
         */
        getSnapshots(options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/snapshots`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get info on snapshot that is marked as source of truth
         * @throws {RequiredError}
         */
        getSourceOfTruthSnapshot(options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/snapshot/source-of-truth`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the task with the given id
         * @throws {RequiredError}
         */
        getTask(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getTask.');
            }
            const localVarPath = `/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the taskassignment with the given id
         * @throws {RequiredError}
         */
        getTaskAssignment(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getTaskAssignment.');
            }
            const localVarPath = `/v1/taskassignment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all taskassignments
         * @throws {RequiredError}
         */
        getTaskAssignments(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/taskassignments/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all task categories
         * @throws {RequiredError}
         */
        getTaskCategories(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/taskcategories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the task category with the given id
         * @throws {RequiredError}
         */
        getTaskCategory(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getTaskCategory.');
            }
            const localVarPath = `/v1/taskcategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all labels
         * @throws {RequiredError}
         */
        getLabels(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/labels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the label with the given id
         * @throws {RequiredError}
         */
        getLabel(id: number, snapshot?: string, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getLabel.');
            }
            const localVarPath = `/v1/label/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all tasks
         * @throws {RequiredError}
         */
        getTasks(snapshot?: string, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/tasks/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            if (snapshot !== undefined) {
                localVarQueryParameter['snapshot'] = ((snapshot: any): string);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Import OPOs from an excel file
         * @throws {RequiredError}
         */
        importOPOs(file?: any, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/opos/import`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();

            if (file !== undefined) {
                localVarFormParams.set('file', ((file: any): string));
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the assignment with the given id
         * @throws {RequiredError}
         */
        updateAssignment(id: number, assignment?: Assignment, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateAssignment.');
            }
            const localVarPath = `/v1/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof assignment !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(assignment != null ? assignment : {}) : (((assignment: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the class size parameter with the given id
         * @throws {RequiredError}
         */
        updateClassSizeParameter(id: number, parameterClassSizeLoad?: ParameterClassSizeLoad, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateClassSizeParameter.');
            }
            const localVarPath = `/v1/parameters/classsize/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof parameterClassSizeLoad !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(parameterClassSizeLoad != null ? parameterClassSizeLoad : {}) : (((parameterClassSizeLoad: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the collega with the given id
         * @throws {RequiredError}
         */
        updateCollega(id: number, collega?: Collega, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateCollega.');
            }
            const localVarPath = `/v1/collega/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof collega !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(collega != null ? collega : {}) : (((collega: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the ECTS load parameter with the given id
         * @throws {RequiredError}
         */
        updateECTSLoadParameter(id: number, parameterECTSLoad?: ParameterECTSLoad, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateECTSLoadParameter.');
            }
            const localVarPath = `/v1/parameters/ectsload/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof parameterECTSLoad !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(parameterECTSLoad != null ? parameterECTSLoad : {}) : (((parameterECTSLoad: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Global Settings
         * @throws {RequiredError}
         */
        updateGlobalSettings(globalSettings?: Any, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            const localVarPath = `/v1/globalsettings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof globalSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(globalSettings != null ? globalSettings : {}) : (((globalSettings: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the OLA with the given id
         * @throws {RequiredError}
         */
        updateOLA(id: number, oLA?: OLA, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateOLA.');
            }
            const localVarPath = `/v1/ola/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof oLA !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(oLA != null ? oLA : {}) : (((oLA: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the OPO with the given id
         * @throws {RequiredError}
         */
        updateOPO(id: number, oPO?: OPO, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateOPO.');
            }
            const localVarPath = `/v1/opo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof oPO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(oPO != null ? oPO : {}) : (((oPO: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the parallel with the given id
         * @throws {RequiredError}
         */
        updateParallel(id: number, parallel?: Parallel, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateParallel.');
            }
            const localVarPath = `/v1/parallel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof parallel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(parallel != null ? parallel : {}) : (((parallel: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the class with the given id
         * @throws {RequiredError}
         */
        updatePhase(id: number, phase?: Phase, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updatePhase.');
            }
            const localVarPath = `/v1/phase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof phase !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(phase != null ? phase : {}) : (((phase: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the programme with the given id
         * @throws {RequiredError}
         */
        updateProgramme(id: number, programme?: Programme, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateProgramme.');
            }
            const localVarPath = `/v1/programme/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof programme !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(programme != null ? programme : {}) : (((programme: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the snapshot that is marked as source of truth
         * @throws {RequiredError}
         */
        updateSourceOfTruthSnapshot(snapshot?: Snapshot, options: RequestOptions): FetchArgs {
            const localVarPath = `/v1/snapshot/source-of-truth`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof snapshot !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(snapshot != null ? snapshot : {}) : (((snapshot: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the task with the given id
         * @throws {RequiredError}
         */
        updateTask(id: number, task?: Task, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateTask.');
            }
            const localVarPath = `/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof task !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(task != null ? task : {}) : (((task: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the taskassignment with the given id
         * @throws {RequiredError}
         */
        updateTaskAssignment(id: number, taskAssignment?: TaskAssignment, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateTaskAssignment.');
            }
            const localVarPath = `/v1/taskassignment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof taskAssignment !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(taskAssignment != null ? taskAssignment : {}) : (((taskAssignment: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the task category with the given id
         * @throws {RequiredError}
         */
        updateTaskCategory(id: number, taskCategory?: TaskCategory, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateTaskCategory.');
            }
            const localVarPath = `/v1/taskcategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof taskCategory !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(taskCategory != null ? taskCategory : {}) : (((taskCategory: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the task category with the given id
         * @throws {RequiredError}
         */
        updateLabel(id: number, label?: Label, options: RequestOptions): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateLabel.');
            }
            const localVarPath = `/v1/label/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: RequestOptions = Object.assign({}, { method: 'PUT' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (typeof taskCategory !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(label != null ? label : {}) : (((label: any): string) || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

export type DefaultApiType = {
    checkAuth(options?: RequestOptions): Promise<Array<Status>>,

    checkRules(options?: RequestOptions): Promise<Array<RuleError>>,

    createAssignment(assignment?: Assignment, options?: RequestOptions): Promise<Assignment>,

    createClassSizeParameter(parameterClassSizeLoad?: ParameterClassSizeLoad, options?: RequestOptions): Promise<ParameterClassSizeLoad>,

    createCollega(collega?: Collega, options?: RequestOptions): Promise<Collega>,

    createECTSLoadParameter(parameterECTSLoad?: ParameterECTSLoad, options?: RequestOptions): Promise<ParameterECTSLoad>,

    createOLA(oLA?: OLA, options?: RequestOptions): Promise<OLA>,

    createOPO(oPO?: OPO, options?: RequestOptions): Promise<OPO>,

    createParallel(parallel?: Parallel, options?: RequestOptions): Promise<Parallel>,

    createPhase(phase?: Phase, options?: RequestOptions): Promise<Phase>,

    createProgramme(programme?: Programme, options?: RequestOptions): Promise<Programme>,

    createSnapshot(options?: RequestOptions): Promise<Snapshot>,

    createTask(task?: Task, options?: RequestOptions): Promise<Task>,

    createTaskAssignment(taskAssignment?: TaskAssignment, options?: RequestOptions): Promise<TaskAssignment>,

    createTaskCategory(taskCategory?: TaskCategory, options?: RequestOptions): Promise<TaskCategory>,

    createLabel(label?: Label, options?: RequestOptions): Promise<Label>,

    deleteAssignment(id: number, options?: RequestOptions): Promise<Status>,

    deleteClassSizeParameter(id: number, options?: RequestOptions): Promise<Status>,

    deleteCollega(id: number, options?: RequestOptions): Promise<Status>,

    deleteECTSLoadParameter(id: number, options?: RequestOptions): Promise<Status>,

    deleteOLA(id: number, options?: RequestOptions): Promise<Status>,

    deleteOPO(id: number, options?: RequestOptions): Promise<Status>,

    deleteParallel(id: number, options?: RequestOptions): Promise<Status>,

    deletePhase(id: number, options?: RequestOptions): Promise<Status>,

    deleteProgramme(id: number, options?: RequestOptions): Promise<Status>,

    deleteTask(id: number, options?: RequestOptions): Promise<Status>,

    deleteTaskAssignment(id: number, options?: RequestOptions): Promise<Status>,

    deleteTaskCategory(id: number, options?: RequestOptions): Promise<Status>,

    deleteLabel(id: number, options?: RequestOptions): Promise<Status>,

    getAssignment(id: number, options?: RequestOptions): Promise<Assignment>,

    getAssignments(snapshot?: string, options?: RequestOptions): Promise<Array<Assignment>>,

    getAssignmentsByCollegaId(collegaID: number, snapshot?: string, options?: RequestOptions): Promise<Array<MyAssignment>>,

    getTaskAssignmentsByCollegaId(collegaID: number, snapshot?: string, options?: RequestOptions): Promise<Array<TaskAssignment>>,

    getAssignmentsExport(snapshot?: string, options?: RequestOptions): Promise<Array<Collega>>,

    getDocentenContacturenPerOpleidingReportPDF(snapshot?: string, options?: RequestOptions): Promise<Any>,

    getDocentenContacturenPerOpleidingReportExcel(snapshot?: string, options?: RequestOptions): Promise<Any>,

    getStaffAssignmentReport(collegaID: number, snapshot?: string, options?: RequestOptions): Promise<Any>,

    getClassSizeParameter(id: number, snapshot?: string, options?: RequestOptions): Promise<ParameterClassSizeLoad>,

    getClassSizesParameters(snapshot?: string, options?: RequestOptions): Promise<Array<ParameterClassSizeLoad>>,

    getCollega(id: number, snapshot?: string, options?: RequestOptions): Promise<Collega>,

    getCollegas(snapshot?: string, options?: RequestOptions): Promise<Array<Collega>>,

    getCollegasExport(snapshot?: string, options?: RequestOptions): Promise<Array<Collega>>,

    getECTSLoadParameter(id: number, snapshot?: string, options?: RequestOptions): Promise<ParameterECTSLoad>,

    getECTSLoadsParameters(snapshot?: string, options?: RequestOptions): Promise<Array<ParameterECTSLoad>>,

    getGeneralParameters(snapshot?: string, options?: RequestOptions): Promise<Any>,

    getGlobalSettings(snapshot?: string, options?: RequestOptions): Promise<Any>,

    getMyAssignments(options?: RequestOptions): Promise<Array<MyAssignment>>,

    getMyTaskAssignments(options?: RequestOptions): Promise<Array<TaskAssignment>>,

    getMyself(snapshot?: string, options?: RequestOptions): Promise<Collega>,

    getOLA(id: number, snapshot?: string, options?: RequestOptions): Promise<OLA>,

    getOPO(id: number, snapshot?: string, options?: RequestOptions): Promise<OPO>,

    getOPOs(snapshot?: string, options?: RequestOptions): Promise<Array<OPO>>,

    getParallel(id: number, snapshot?: string, options?: RequestOptions): Promise<Parallel>,

    getParallels(snapshot?: string, options?: RequestOptions): Promise<Array<Parallel>>,

    getPhase(id: number, snapshot?: string, options?: RequestOptions): Promise<Phase>,

    getPhases(programmeID?: number, snapshot?: string, options?: RequestOptions): Promise<Array<Phase>>,

    getProgramme(id: number, snapshot?: string, options?: RequestOptions): Promise<Programme>,

    getProgrammes(snapshot?: string, options?: RequestOptions): Promise<Array<Programme>>,

    getRoles(options?: RequestOptions): Promise<Array<Role>>,

    getSnapshotDiff(from: number, to: number, options?: RequestOptions): Promise<Snapshot>,

    getSnapshots(options?: RequestOptions): Promise<Array<Snapshot>>,

    getSourceOfTruthSnapshot(options?: RequestOptions): Promise<Snapshot>,

    getTask(id: number, snapshot?: string, options?: RequestOptions): Promise<Task>,

    getTaskAssignment(id: number, snapshot?: string, options?: RequestOptions): Promise<TaskAssignment>,

    getTaskAssignments(snapshot?: string, options?: RequestOptions): Promise<Array<TaskAssignment>>,

    getTaskCategories(snapshot?: string, options?: RequestOptions): Promise<Array<TaskCategory>>,

    getTaskCategory(id: number, snapshot?: string, options?: RequestOptions): Promise<TaskCategory>,

    getLabels(snapshot?: string, options?: RequestOptions): Promise<Array<Label>>,

    getLabel(id: number, snapshot?: string, options?: RequestOptions): Promise<Label>,

    getTasks(snapshot?: string, options?: RequestOptions): Promise<Array<Task>>,

    importOPOs(file?: any, options?: RequestOptions): Promise<Array<OPO>>,

    updateAssignment(id: number, assignment?: Assignment, options?: RequestOptions): Promise<Assignment>,

    updateClassSizeParameter(id: number, parameterClassSizeLoad?: ParameterClassSizeLoad, options?: RequestOptions): Promise<ParameterClassSizeLoad>,

    updateCollega(id: number, collega?: Collega, options?: RequestOptions): Promise<Collega>,

    updateECTSLoadParameter(id: number, parameterECTSLoad?: ParameterECTSLoad, options?: RequestOptions): Promise<ParameterECTSLoad>,

    updateGlobalSettings(globalSettings?: ParameterECTSLoad, options?: RequestOptions): Promise<Any>,

    updateOLA(id: number, oLA?: OLA, options?: RequestOptions): Promise<OLA>,

    updateOPO(id: number, oPO?: OPO, options?: RequestOptions): Promise<OPO>,

    updateParallel(id: number, parallel?: Parallel, options?: RequestOptions): Promise<Parallel>,

    updatePhase(id: number, phase?: Phase, options?: RequestOptions): Promise<Phase>,

    updateProgramme(id: number, programme?: Programme, options?: RequestOptions): Promise<Programme>,

    updateSourceOfTruthSnapshot(snapshot?: Snapshot, options?: RequestOptions): Promise<Snapshot>,

    updateTask(id: number, task?: Task, options?: RequestOptions): Promise<Task>,

    updateTaskAssignment(id: number, taskAssignment?: TaskAssignment, options?: RequestOptions): Promise<TaskAssignment>,

    updateTaskCategory(id: number, taskCategory?: TaskCategory, options?: RequestOptions): Promise<TaskCategory>,

    updateLabel(id: number, label?: Label, options?: RequestOptions): Promise<Label>,
}

/**
 * DefaultApi - factory function to inject configuration 
 * @export
 */
export const DefaultApi = function (configuration?: Configuration, fetch: FetchAPI = portableFetch): DefaultApiType {
    const basePath: string = (configuration && configuration.basePath) || BASE_PATH;
    return {
        /**
         * Check if the user is authenticated
         * @throws {RequiredError}
         */
        checkAuth(options?: RequestOptions = {}): Promise<Array<Status>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).checkAuth(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Check for errors in the database using the rule engine
         * @throws {RequiredError}
         */
        checkRules(options?: RequestOptions = {}): Promise<Array<RuleError>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).checkRules(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new assignment
         * @throws {RequiredError}
         */
        createAssignment(assignment?: Assignment, options?: RequestOptions = {}): Promise<Assignment> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createAssignment(assignment, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new class size parameter
         * @throws {RequiredError}
         */
        createClassSizeParameter(parameterClassSizeLoad?: ParameterClassSizeLoad, options?: RequestOptions = {}): Promise<ParameterClassSizeLoad> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createClassSizeParameter(parameterClassSizeLoad, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new collega
         * @throws {RequiredError}
         */
        createCollega(collega?: Collega, options?: RequestOptions = {}): Promise<Collega> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createCollega(collega, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new ECTS load parameter
         * @throws {RequiredError}
         */
        createECTSLoadParameter(parameterECTSLoad?: ParameterECTSLoad, options?: RequestOptions = {}): Promise<ParameterECTSLoad> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createECTSLoadParameter(parameterECTSLoad, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new OLA
         * @throws {RequiredError}
         */
        createOLA(oLA?: OLA, options?: RequestOptions = {}): Promise<OLA> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createOLA(oLA, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new OPO
         * @throws {RequiredError}
         */
        createOPO(oPO?: OPO, options?: RequestOptions = {}): Promise<OPO> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createOPO(oPO, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new parallel
         * @throws {RequiredError}
         */
        createParallel(parallel?: Parallel, options?: RequestOptions = {}): Promise<Parallel> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createParallel(parallel, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new phase
         * @throws {RequiredError}
         */
        createPhase(phase?: Phase, options?: RequestOptions = {}): Promise<Phase> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createPhase(phase, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new programme
         * @throws {RequiredError}
         */
        createProgramme(programme?: Programme, options?: RequestOptions = {}): Promise<Programme> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createProgramme(programme, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new snapshot
         * @throws {RequiredError}
         */
        createSnapshot(options?: RequestOptions = {}): Promise<Snapshot> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createSnapshot(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new task
         * @throws {RequiredError}
         */
        createTask(task?: Task, options?: RequestOptions = {}): Promise<Task> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createTask(task, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new taskassignment
         * @throws {RequiredError}
         */
        createTaskAssignment(taskAssignment?: TaskAssignment, options?: RequestOptions = {}): Promise<TaskAssignment> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createTaskAssignment(taskAssignment, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new task category
         * @throws {RequiredError}
         */
        createTaskCategory(taskCategory?: TaskCategory, options?: RequestOptions = {}): Promise<TaskCategory> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createTaskCategory(taskCategory, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Creates a new label
         * @throws {RequiredError}
         */
        createLabel(label?: Label, options?: RequestOptions = {}): Promise<Label> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).createLabel(label, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the assignment with the given id
         * @throws {RequiredError}
         */
        deleteAssignment(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteAssignment(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the class size parameter with the given id
         * @throws {RequiredError}
         */
        deleteClassSizeParameter(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteClassSizeParameter(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the collega with the given id
         * @throws {RequiredError}
         */
        deleteCollega(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteCollega(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the ECTS load parameter with the given id
         * @throws {RequiredError}
         */
        deleteECTSLoadParameter(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteECTSLoadParameter(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the OLA with the given id
         * @throws {RequiredError}
         */
        deleteOLA(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteOLA(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the OPO with the given id
         * @throws {RequiredError}
         */
        deleteOPO(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteOPO(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the parallel with the given id
         * @throws {RequiredError}
         */
        deleteParallel(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteParallel(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the class with the given id
         * @throws {RequiredError}
         */
        deletePhase(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deletePhase(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the programme with the given id
         * @throws {RequiredError}
         */
        deleteProgramme(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteProgramme(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the task with the given id
         * @throws {RequiredError}
         */
        deleteTask(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteTask(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the taskassignment with the given id
         * @throws {RequiredError}
         */
        deleteTaskAssignment(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteTaskAssignment(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the task category with the given id
         * @throws {RequiredError}
         */
        deleteTaskCategory(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteTaskCategory(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Deletes the label with the given id
         * @throws {RequiredError}
         */
        deleteLabel(id: number, options?: RequestOptions = {}): Promise<Status> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteLabel(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get the assignment with the given id
         * @throws {RequiredError}
         */
        getAssignment(id: number, options?: RequestOptions = {}): Promise<Assignment> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getAssignment(id, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get all assignments
         * @throws {RequiredError}
         */
        getAssignments(snapshot?: string, options?: RequestOptions = {}): Promise<Array<Assignment>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getAssignments(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        getAssignmentsByCollegaId(collegaID: number, snapshot?: string, options?: RequestOptions = {}): Promise<Array<MyAssignment>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getAssignmentsByCollegaId(collegaID, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        getTaskAssignmentsByCollegaId(collegaID: number, snapshot?: string, options?: RequestOptions = {}): Promise<Array<TaskAssignment>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTaskAssignmentsByCollegaId(collegaID, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns all assignments in Excel
         * @throws {RequiredError}
         */
        getAssignmentsExport(snapshot?: string, options?: RequestOptions = {}): Promise<Array<Collega>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getAssignmentsExport(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * getDocentenContacturenPerOpleidingReport Excel
         * @throws {RequiredError}
         */
        getDocentenContacturenPerOpleidingReportExcel(snapshot?: string, options?: RequestOptions = {}): Promise<Any> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getDocentenContacturenPerOpleidingReportExcel(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * getDocentenContacturenPerOpleidingReport PDF
         * @throws {RequiredError}
         */
        getDocentenContacturenPerOpleidingReportPDF(snapshot?: string, options?: RequestOptions = {}): Promise<Any> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getDocentenContacturenPerOpleidingReportPDF(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Report of staff assignments
         * @throws {RequiredError}
         */
        getStaffAssignmentReport(collegaID: number, snapshot?: string, options?: RequestOptions = {}): Promise<Any> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getStaffAssignmentReport(collegaID, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns the class size parameter with the given id
         * @throws {RequiredError}
         */
        getClassSizeParameter(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<ParameterClassSizeLoad> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getClassSizeParameter(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get class sizes parameters
         * @throws {RequiredError}
         */
        getClassSizesParameters(snapshot?: string, options?: RequestOptions = {}): Promise<Array<ParameterClassSizeLoad>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getClassSizesParameters(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns the collega with the given id
         * @throws {RequiredError}
         */
        getCollega(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<Collega> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCollega(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns all collegas
         * @throws {RequiredError}
         */
        getCollegas(snapshot?: string, options?: RequestOptions = {}): Promise<Array<Collega>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCollegas(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns all collegas in Excel
         * @throws {RequiredError}
         */
        getCollegasExport(snapshot?: string, options?: RequestOptions = {}): Promise<Array<Collega>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getCollegasExport(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns the ECTS load parameter with the given id
         * @throws {RequiredError}
         */
        getECTSLoadParameter(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<ParameterECTSLoad> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getECTSLoadParameter(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get ECTS loads parameters
         * @throws {RequiredError}
         */
        getECTSLoadsParameters(snapshot?: string, options?: RequestOptions = {}): Promise<Array<ParameterECTSLoad>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getECTSLoadsParameters(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get general parameters
         * @throws {RequiredError}
         */
        getGeneralParameters(snapshot?: string, options?: RequestOptions = {}): Promise<ParameterGeneral> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getGeneralParameters(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get global settings
         * @throws {RequiredError}
         */
        getGlobalSettings(snapshot?: string, options?: RequestOptions = {}): Promise<Any> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getGlobalSettings(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get all assignments of current user
         * @throws {RequiredError}
         */
        getMyAssignments(options?: RequestOptions = {}): Promise<Array<MyAssignment>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getMyAssignments(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get all task assignments of current user
         * @throws {RequiredError}
         */
        getMyTaskAssignments(options?: RequestOptions = {}): Promise<Array<TaskAssignment>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getMyTaskAssignments(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns the collega for the current logged in user
         * @throws {RequiredError}
         */
        getMyself(snapshot?: string, options?: RequestOptions = {}): Promise<Collega> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getMyself(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns the OLA with the given id
         * @throws {RequiredError}
         */
        getOLA(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<OLA> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOLA(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns the OPO with the given id
         * @throws {RequiredError}
         */
        getOPO(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<OPO> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOPO(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns all OPOs
         * @throws {RequiredError}
         */
        getOPOs(snapshot?: string, options?: RequestOptions = {}): Promise<Array<OPO>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOPOs(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns the parallel with the given id
         * @throws {RequiredError}
         */
        getParallel(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<Parallel> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getParallel(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns all parallels
         * @throws {RequiredError}
         */
        getParallels(snapshot?: string, options?: RequestOptions = {}): Promise<Array<Parallel>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getParallels(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns the class with the given id
         * @throws {RequiredError}
         */
        getPhase(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<Phase> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getPhase(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns all phases
         * @throws {RequiredError}
         */
        getPhases(programmeID?: number, snapshot?: string, options?: RequestOptions = {}): Promise<Array<Phase>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getPhases(programmeID, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns the programme with the given id
         * @throws {RequiredError}
         */
        getProgramme(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<Programme> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getProgramme(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns all programmes
         * @throws {RequiredError}
         */
        getProgrammes(snapshot?: string, options?: RequestOptions = {}): Promise<Array<Programme>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getProgrammes(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Returns all roles of the current user
         * @throws {RequiredError}
         */
        getRoles(options?: RequestOptions = {}): Promise<Array<Role>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getRoles(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get the diff between two snapshots
         * @throws {RequiredError}
         */
        getSnapshotDiff(from: number, to: number, options?: RequestOptions = {}): Promise<Snapshot> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getSnapshotDiff(from, to, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get all snapshots
         * @throws {RequiredError}
         */
        getSnapshots(options?: RequestOptions = {}): Promise<Array<Snapshot>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getSnapshots(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get info on snapshot that is marked as source of truth
         * @throws {RequiredError}
         */
        getSourceOfTruthSnapshot(options?: RequestOptions = {}): Promise<Snapshot> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getSourceOfTruthSnapshot(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get the task with the given id
         * @throws {RequiredError}
         */
        getTask(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<Task> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTask(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get the taskassignment with the given id
         * @throws {RequiredError}
         */
        getTaskAssignment(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<TaskAssignment> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTaskAssignment(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get all taskassignments
         * @throws {RequiredError}
         */
        getTaskAssignments(snapshot?: string, options?: RequestOptions = {}): Promise<Array<TaskAssignment>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTaskAssignments(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get all task categories
         * @throws {RequiredError}
         */
        getTaskCategories(snapshot?: string, options?: RequestOptions = {}): Promise<Array<TaskCategory>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTaskCategories(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get the task category with the given id
         * @throws {RequiredError}
         */
        getTaskCategory(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<TaskCategory> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTaskCategory(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get all labels
         * @throws {RequiredError}
         */
        getLabels(snapshot?: string, options?: RequestOptions = {}): Promise<Array<Label>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getLabels(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get the label with the given id
         * @throws {RequiredError}
         */
        getLabel(id: number, snapshot?: string, options?: RequestOptions = {}): Promise<Label> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getLabel(id, snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Get all tasks
         * @throws {RequiredError}
         */
        getTasks(snapshot?: string, options?: RequestOptions = {}): Promise<Array<Task>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getTasks(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Import OPOs from an excel file
         * @throws {RequiredError}
         */
        importOPOs(file?: any, options?: RequestOptions = {}): Promise<Array<OPO>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).importOPOs(file, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the assignment with the given id
         * @throws {RequiredError}
         */
        updateAssignment(id: number, assignment?: Assignment, options?: RequestOptions = {}): Promise<Assignment> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateAssignment(id, assignment, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the class size parameter with the given id
         * @throws {RequiredError}
         */
        updateClassSizeParameter(id: number, parameterClassSizeLoad?: ParameterClassSizeLoad, options?: RequestOptions = {}): Promise<ParameterClassSizeLoad> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateClassSizeParameter(id, parameterClassSizeLoad, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the collega with the given id
         * @throws {RequiredError}
         */
        updateCollega(id: number, collega?: Collega, options?: RequestOptions = {}): Promise<Collega> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateCollega(id, collega, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the ECTS load parameter with the given id
         * @throws {RequiredError}
         */
        updateECTSLoadParameter(id: number, parameterECTSLoad?: ParameterECTSLoad, options?: RequestOptions = {}): Promise<ParameterECTSLoad> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateECTSLoadParameter(id, parameterECTSLoad, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },

        /**
         * Updates the ECTS load parameter with the given id
         * @throws {RequiredError}
         */
        updateGlobalSettings(globalSettings?: Any, options?: RequestOptions = {}): Promise<Any> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateGlobalSettings(globalSettings, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the OLA with the given id
         * @throws {RequiredError}
         */
        updateOLA(id: number, oLA?: OLA, options?: RequestOptions = {}): Promise<OLA> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateOLA(id, oLA, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the OPO with the given id
         * @throws {RequiredError}
         */
        updateOPO(id: number, oPO?: OPO, options?: RequestOptions = {}): Promise<OPO> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateOPO(id, oPO, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the parallel with the given id
         * @throws {RequiredError}
         */
        updateParallel(id: number, parallel?: Parallel, options?: RequestOptions = {}): Promise<Parallel> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateParallel(id, parallel, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the class with the given id
         * @throws {RequiredError}
         */
        updatePhase(id: number, phase?: Phase, options?: RequestOptions = {}): Promise<Phase> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updatePhase(id, phase, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the programme with the given id
         * @throws {RequiredError}
         */
        updateProgramme(id: number, programme?: Programme, options?: RequestOptions = {}): Promise<Programme> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateProgramme(id, programme, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the snapshot that is marked as source of truth
         * @throws {RequiredError}
         */
        updateSourceOfTruthSnapshot(snapshot?: Snapshot, options?: RequestOptions = {}): Promise<Snapshot> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateSourceOfTruthSnapshot(snapshot, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the task with the given id
         * @throws {RequiredError}
         */
        updateTask(id: number, task?: Task, options?: RequestOptions = {}): Promise<Task> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateTask(id, task, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the taskassignment with the given id
         * @throws {RequiredError}
         */
        updateTaskAssignment(id: number, taskAssignment?: TaskAssignment, options?: RequestOptions = {}): Promise<TaskAssignment> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateTaskAssignment(id, taskAssignment, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the task category with the given id
         * @throws {RequiredError}
         */
        updateTaskCategory(id: number, taskCategory?: TaskCategory, options?: RequestOptions = {}): Promise<TaskCategory> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateTaskCategory(id, taskCategory, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
        /**
         * Updates the task category with the given id
         * @throws {RequiredError}
         */
        updateLabel(id: number, label?: Label, options?: RequestOptions = {}): Promise<Label> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).updateLabel(id, label, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },
    }
};


export type ApiTypes = {
    DefaultApi: DefaultApiType,
}
