import { Button, Container } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
//import { isLoggedIn, getRedirectURL, login } from "./_services/oidc";
import AppNav from "./Nav";
import Home from "./pages/home/Home";
import Collegas from "./pages/collegas/Collegas";
import Programmes from "./pages/programmes/Programmes";
import OPOs from "./pages/opos/OPOs";
import Phases from "./pages/phases/Phases";
import Parameters from "./pages/parameters/Parameters";
import GlobalToast from "./pages/_components/GlobalToast";
import OLAAssignment from "./pages/assignment/olas/OLAs";
import TaskAssignment from "./pages/assignment/tasks/tasks";
import Tasks from "./pages/tasks/Tasks";
import PersonalSetup from "./pages/personalsetup/personalSetup";
import PerformanceScheme from "./pages/performancescheme/performanceScheme";
import TaskCategories from "./pages/taskcategories/TaskCategories";
import Labels from "./pages/labels/Labels";
import store from "./redux/store";
import "./scss/App.scss";
import RulesCheck from "./pages/rules/Check";
import PersonalView from "./pages/assignment/Kanban/PersonalView";
import ManagePerfomancescheme from "./pages/manage-performancescheme/ManagePerfomancescheme";
import Parallels from "./pages/parallels/Parallels";
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from './_azure/authConfig';
import SatAPI from "./_services/sat";
import { Helmet } from "react-helmet";
import CoffeeBreak from "./pages/leisure/coffee-break";
import Konami from "react-konami-code";
import Reports from "./pages/reporting/reports";


/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const MainContent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create',
      })
      .catch((error) => console.log(error));
  };



  let title = "Thomas More SAT";
  if (
    (localStorage.getItem("name") === "Brent Pulmans" || localStorage.getItem("name") === "Jitse Beyens") &&
    window.location.href.indexOf("sat.thomasmore.be") > -1
  ) {
    title = "REAL DEAL - Thomas More SAT";
  }

  if (window.location.href.indexOf("sat-sandbox.thomasmore.be") > -1) {
    title = "SANDBOX - Thomas More SAT";
  }

  if (window.location.href.indexOf("localhost") > -1) {
    title = "DEVELOPMENT - Thomas More SAT";
  }


  return (
    <div className="App">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <SatBody />
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Home />
          <Button onClick={handleRedirect}>Login via TM AzureAD</Button>
        </div>
      </UnauthenticatedTemplate>

    </div>
  );
};

const SatBody = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const api = SatAPI();

  const setLocalStorage = async () => {
    if (!localStorage.getItem("SAT-PersonalProgrammeFilter")) {
      localStorage.setItem("SAT-PersonalProgrammeFilter", "[]");
    }
    if (activeAccount && !localStorage.getItem("name")) {
      const user = await api.getMyself();
      console.log(user)
      if (user) {
        localStorage.setItem("name", user.firstName + " " + user.lastName);
        localStorage.setItem("isSATUser", user.isSATUser);
        localStorage.setItem("email", user.uNumber + "@thomasmore.be");
        localStorage.setItem("username", user.uNumber);
        if (user.isSATUser) {
          window.location.reload();
        }
      }
    }
  }

  setLocalStorage();

  const easterEgg = () => {
    if (confirm("Tijd voor pauze?")) {
      window.location.href = "/time-for-a-break";
    }
  }

  return (
    <Provider store={store}>
      <Router>
        <AppNav />
        <Container className="content-page">
          <GlobalToast />
          <Switch>
            <Route path="/collegas/:id">
              <Collegas />
            </Route>
            <Route path="/collegas">
              <Collegas />
            </Route>
            <Route path="/programmes">
              <Programmes />
            </Route>
            <Route path="/Phases">
              <Phases />
            </Route>
            <Route path="/opos">
              <OPOs />
            </Route>
            <Route path="/parameters">
              <Parameters />
            </Route>
            <Route path="/toewijzing/olas">
              <OLAAssignment />
            </Route>
            <Route path="/toewijzing/teachers">
              <PersonalView />
            </Route>
            <Route path="/toewijzing/tasks">
              <TaskAssignment />
            </Route>
            <Route path="/rules/check">
              <RulesCheck />
            </Route>
            <Route path="/tasks">
              <Tasks />
            </Route>
            <Route path="/personalsetup">
              <PersonalSetup />
            </Route>
            <Route path="/myperformancescheme">
              <PerformanceScheme />
            </Route>
            <Route path="/manage-performancescheme">
              <ManagePerfomancescheme />
            </Route>
            <Route path="/taskcategories">
              <TaskCategories />
            </Route>
            <Route path="/labels">
              <Labels />
            </Route>
            <Route path="/parallels">
              <Parallels />
            </Route>
            <Route path="/time-for-a-break">
              <CoffeeBreak />
            </Route>
            <Route path="/reports">
              <Reports />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Container>
        <Konami action={easterEgg}></Konami>
      </Router>
    </Provider>
  );
}



const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
}

export default App;
